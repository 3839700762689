<template>
  <div class="box">
    <van-tabs @click="scrollToAll" v-model="active">
      <van-tab v-for="(item, index) in list" :title="getTime(item.showTime)" :key="index">
        <div v-for="(item1, index1) in item.filmDetailList" :key="index1" class="font-s-14 bgc flex jcsb"
          @click="goDetail(item1.cinemaId, item.filmId, item1.cinemaName, item1.cinemaAddr, item.showTime)">
          <div style="max-width: 18rem;">
            <div>
              <span class="textname"> {{ item1.cinemaName }}</span>
            </div>
            <div style="margin-top: 0.5rem; ">
              <span class="textcon1"> {{ item1.cinemaAddr }}</span>
            </div>
            <div style="margin-top: 0.5rem;min-width: 16rem; ">
              <div class="textcon">{{ getCC(item1.showsDate) }}</div>
            </div>
          </div>
          <div style="color:#D23B2E;"> {{ getKm(item1.gap) }}</div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "",
  props: {

    list: {
      type: Array,
    },
  },
  data() {
    return {
      active: 0

    };
  },
  components: {},
  methods: {
    getCC(val) {
      return '场次:' + val.replaceAll('|', "  ")
    },
    goDetail(val, val1, name, address, time) {
      this.$router.push({
        path: "/CinemaDetail",
        query: {
          filmId: val1,
          name: name,
          address: address,
          cinemaId: val,
          time: time
        },
      });
    },
    getTime(str) {
      return this.$moment(str).format("MM月DD日")
    },
    getKm(gap) {
      if (gap > 1000) {
        return '>1000km'
      } else {
        return gap + 'km'
      }
    },
    setIndex(index) {
      this.active = index
    },
    scrollToAll(index) {
      this.$emit("scrollToAll", index);
    }
  },

  mounted() {
    // console.log(this.list);

  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.box {
  margin: 0.57rem 1rem 0 1rem;

  .bgc {
    background-color: #fff;
    border-radius: 0.5rem;
    margin-top: 0.57rem;
    padding: 1rem;
  }
}

.textname {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #000;
  font-size: 1.1rem;
}

.textcon1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 0.3rem;
  font-size: 0.9rem;
}


::v-deep .van-tab {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  color: #999;
  font-size: 0.85rem;
  line-height: 1.8rem;
  cursor: pointer;
  max-width: 5.7rem;
  min-width: 5.7rem;
}



::v-deep .van-tabs--line .van-tabs__wrap {
  height: 2rem;

  .van-tabs__nav {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    background-color: #00000000;
    -webkit-user-select: none;
    user-select: none;
  }
}

::v-deep .van-tab--active {
  color: white;
  font-weight: 500;
  background: #D23B2E;
  border-radius: 0.9rem;
  height: 1.8rem;
  margin-top: 0.1rem;
  max-width: 5.7rem;
}

::v-deep .van-tabs__line {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 10.667vw;
  height: 0;

}


.textcon {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #666;
  margin-top: 0.3rem;
  font-size: 0.85rem;

}
</style>