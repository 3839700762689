<template>
  <div class="height-100 bgc">
    <div style="display: flex;height: 3rem; background-color: white;">
      <div class="flex jcc t-a-c" @click="goback">
        <div style="margin-left: 1rem;" class="iconfont icon-zuojiantou"></div>
      </div>
      <van-search v-model="hotMovie.cinemaName" placeholder="搜索影院" style="width: 100%;" shape="round" show-action
        background="#00000000" @search="queryCinemas">
        <template #action>
          <div @click="seach">搜索</div>
        </template>
      </van-search>
    </div>
    <div v-if="type == 1">
      <van-list v-if="Cinemas.length > 0" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <cinema-item :list="Cinemas">
        </cinema-item>
      </van-list>
    </div>
    <div v-else>
      <div v-if="cinema.length > 0" style="overflow: scroll;height: 100vh;">
        <cinema-time-item :list="cinema">
        </cinema-time-item>
      </div>
    </div>

  </div>
</template>

<script>
import CinemaTimeItem from "../../components/movie/CinemaTimeItem.vue";
import CinemaItem from "../../components/movie/CinemaItem.vue";
let initViewfinish = false
export default {
  name: 'CinemasList',
  data() {
    return {
      type: 0,
      hotMovie: {
        regionId: '',
        cardId: '',
        cityCode: '',
        current: 1,
        filmId: '',
        size: 500,
        lat: null,
        lng: null,
        cinemaName: '',
      },
      Cinemas: [],
      cinema: [],
      loading: false,
      finished: false,
      boo3: true,
    }
  },
  created() { },
  components: { CinemaTimeItem, CinemaItem },
  methods: {
    goback() {
      this.$router.back()
    },
    seach() {
      this.queryCinemas()
    },
    // 刷新
    onRefresh() {
      this.hotMovie.current = 1
      this.queryCinemas()
    },
    onLoad() {
      if (this.Cinemas.length >= 10) {
        this.hotMovie.current = this.hotMovie.current + 1
        this.queryCinemas()
      }
    },
    queryCinemas() {
      if (this.type == 1) {
        //影院
        this.hotMovie.size = 10
        this.$api
          .queryCinemas(this.hotMovie)
          .then((res) => {
            if (res.code == 1) {
              this.$Toast(res.msg);
              return
            }
            if (this.hotMovie.current == 1) {
              this.Cinemas = res.data.records
            } else {
              this.Cinemas.push(...res.data.records)
            }
            if (res.data.records.length < 10) {
              this.finished = true
            }
            this.loading = false
            this.isLoading = false
          })
          .catch((err) => {
            this.finished = true
            this.loading = false
            this.isLoading = false
          });
      } else
        this.$api
          .queryFutureFilmsByFilmId(this.hotMovie)
          .then((res) => {
            this.loading = false
            if (res.code == 1) {
              this.$Toast(res.msg);
              return
            }
            this.cinema = res.data.futureFilms
          })
          .catch((res) => {
            this.loading = false
            // this.$Toast(res);
          });
    },
    initView() {
      this.hotMovie.filmId = this.$route.query.filmId
      this.type = this.$route.query.type
      this.hotMovie.cityCode = this.$route.query.cityCode
      this.hotMovie.regionId = this.$route.query.regionId
      this.hotMovie.cinemaName = ''
      this.Cinemas = []
      this.cinema = []
      this.hotMovie.cardId = localStorage.getItem("cardId");
      this.hotMovie.lat = localStorage.getItem('latitude')
      this.hotMovie.lng = localStorage.getItem('longitude')
    }
  },
  // //在页面离开时记录滚动位置
  mounted() {

    // this.queryCinemas()
  },
  beforeRouteEnter(to, from, next) {
    to.meta.keepAlive = true
    if (from.path !== "/CinemaDetail") {
      next((vm) => {
        vm.$nextTick(() => {
          vm.initView()
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0
        })
      });
    } else {
      initViewfinish = true
      next();
    }
  },
  created() {
    if (initViewfinish) {
      initViewfinish = false
      this.initView()
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0
    }
  },
}

</script>
<style lang="scss" scoped>
.bgc {
  background-color: F8F8F8;
}
</style>